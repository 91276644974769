#login-header {
  background-image: url("../img/theme/tasatool-bg.svg") !important;
  background-size: cover !important;
}
#sidenav-main {
  background-image: url("../img/theme/sidebar-bg.png");
  .nav-link {
    color: #fff;
  }
  .nav-item {
    .collapse-navbar {
      .nav-item {
        .nav-link {
          padding-left: 65px;
        }
      }
    }
  }
}

.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 0.75rem;
  border: 1px solid rgb(214, 216, 218);
  border-radius: 6px;
  &:focus-within {
    border-color: rgba(50, 151, 211, 0.25);
  }
  input {
    flex: 1;
    border: none;
    height: 46px;
    font-size: 14px;
    padding: 4px 0 0 0;
    &:focus {
      outline: transparent;
    }
  }
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag {
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: #0052cc;
  .tag-title {
    margin-top: 3px;
  }
  .tag-close-icon {
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 14px;
    margin-left: 8px;
    color: #0052cc;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
  }
}

.cursor-alias {
  cursor: alias;
}
.cursor-all-scroll {
  cursor: all-scroll;
}
.cursor-auto {
  cursor: auto;
}
.cursor-cell {
  cursor: cell;
}
.cursor-context-menu {
  cursor: context-menu;
}
.cursor-col-resize {
  cursor: col-resize;
}
.cursor-copy {
  cursor: copy;
}
.cursor-crosshair {
  cursor: crosshair;
}
.cursor-default {
  cursor: default;
}
.cursor-e-resize {
  cursor: e-resize;
}
.cursor-ew-resize {
  cursor: ew-resize;
}
.cursor-grab {
  cursor: -webkit-grab;
  cursor: grab;
}
.cursor-grabbing {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.cursor-help {
  cursor: help;
}
.cursor-move {
  cursor: move;
}
.cursor-n-resize {
  cursor: n-resize;
}
.cursor-ne-resize {
  cursor: ne-resize;
}
.cursor-nesw-resize {
  cursor: nesw-resize;
}
.cursor-ns-resize {
  cursor: ns-resize;
}
.cursor-nw-resize {
  cursor: nw-resize;
}
.cursor-nwse-resize {
  cursor: nwse-resize;
}
.cursor-no-drop {
  cursor: no-drop;
}
.cursor-none {
  cursor: none;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-progress {
  cursor: progress;
}
.cursor-row-resize {
  cursor: row-resize;
}
.cursor-s-resize {
  cursor: s-resize;
}
.cursor-se-resize {
  cursor: se-resize;
}
.cursor-sw-resize {
  cursor: sw-resize;
}
.cursor-text {
  cursor: text;
}
.cursor-w-resize {
  cursor: w-resize;
}
.cursor-wait {
  cursor: wait;
}
.cursor-zoom-in {
  cursor: zoom-in;
}
.cursor-zoom-out {
  cursor: zoom-out;
}
.form-control {
  height: 35px;
  padding: 3px 8px;
}
.input-group-text {
  padding: 3px 8px;
}
#comparative {
  .table th, .table td {
    padding: 0.25rem
  }
}
.tab-content {
  padding: 10px;
  border: 1px solid #dee2e6;
}
#rateInformation, #supplyInformation {
  .list-group-item {
    padding: 0;
    border: none;
  }
  #consumptions {
    text-align: center;
    margin-top: 10px;
    .btn.rounded-circle {
      color: white;
      background-color: #11cdef;
      width: 6rem;
      height: 6rem;
      border: 1px solid lightgrey;
    }
    span.btn-inner {
      display: block;
      margin-top: 10px;
        font-size: 10px;
    }
    span.btn-inner:not(:last-child) {
      border-bottom: 1px solid white;
      margin-bottom: 5px;
      padding-bottom: 5px;
    }
  }

  #consumptionsPot {
    text-align: center;
    margin-top: 10px;
    .btn.rounded-circle-p {
      color: white;
      background-color: #82be4e;
      width: 6rem;
      height: 6rem;
      border: 1px solid lightgrey;
    }
    span.btn-inner-p {
      display: block;
      margin-top: 10px;
    font-size: 10px;
    }
    span.btn-inner-p:not(:last-child) {
      border-bottom: 1px solid white;
      margin-bottom: 5px;
      padding-bottom: 5px;
    }
  }




}
.form-group {
  margin-bottom: .5rem;
  .react-datepicker-wrapper {
    display: block;
  }
}
label {
  margin-bottom: 0.25rem;
}
.app-calendar {
  border: 1px  solid  $primary;
  .react-datepicker__navigation--previous{
    border-right-color: $white;
  }
  .react-datepicker__navigation--next{
    border-left-color: $white;
  }
  .react-datepicker__triangle{
       border-bottom-color:  $primary;

  }
  .react-datepicker__month-container {
    .react-datepicker__header {
      background-color: $primary;
      .react-datepicker__current-month {
        color: $white;
        text-transform: capitalize;
      }
      .react-datepicker__day-names {
        .react-datepicker__day-name {
          color: $white;
        }
      }
    }
  }
}

.react-datepicker-popper[data-placement^="bottom"]{
  .react-datepicker__triangle{
    border-bottom-color:  $primary;
    &:before{
      border-bottom-color: $primary;
    }
  }
}
.react-datepicker-popper[data-placement^="top"]{
  .react-datepicker__triangle{
    &:before{
      border-top-color: $primary;
    }
  }
}

.react-datepicker__day--selected{
  background-color: $primary;
}
