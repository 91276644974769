@font-face {
font-family: "Open Sans";
src: local("Open Sans"),
 url("./assets/fonts/OpenSans/OpenSans-Regular-webfont.ttf") format("truetype");
}

@font-face {
font-family: "Open Sans Bold";
src: local("Open Sans Bold"),
 url("./assets/fonts/OpenSans/OpenSans-Bold-webfont.ttf") format("truetype");
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-dash {
  min-height: 95vh;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}



/* SipComparativeFreeModal.tsx*/
.modal-sip-comparative-free .modal-info
{
  background: #FA5661;
  height: 100px;
  width: 100%;
  border-radius: 5px;
  display:flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.modal-sip-comparative-free .modal-info h1
{
  color:white;
}

/* ComercialProducto.tsx*/
.card-billing-template
{

}
.card-billing-template .panel-data-resolve
{
  height: 380px;
  padding-top: 30px;
  padding-bottom: 30px
}

.card-billing-template .panel-data-resolve .item-data
{
  display:flex;
  justify-content: space-between;
  height: 45px;
}

.card-billing-template input
{
  border: 0;
  background: #FFDFDF;
  text-align: right;

}
.card-billing-template input:focus
{
  border: 0;
  background: #FFDFDF;
  text-align: right;
}

.card-billing-template .input-control
{
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.card-billing-template .item-total
{
  display:flex;
  justify-content: space-between;
  height: 40px;
  background: #11CDEF;
  align-items: center;
  color: white;
  margin-top: 15px;
}
.card-billing-template .item-total h2
{
  color: white;
}

.card-billing-template .item-header
{
  display:flex;
  height: 40px;
  background: white;
  align-items: center;
  color: black;
}

.margin-reset {
  margin: 0px !important;
}


.card-billing-results .item-header{
  background: #82be4e;
  color: white;
}

.card-billing-results .col-result-left{
  background: #82be4e;
  border: 5px solid white;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  color: white;
  height: 400px;
}
.card-billing-results .col-result-right-top, .card-billing-results .col-result-right-bottom{
  background: #82be4e;
  border: 5px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  margin-left: 5px;
  color: white;
  height: 100%;
}
.question {
  width: 500px;
  height: 500px;
}
.question-container {
  width: 100%;
  height: 100%;
}
.chart-container {
  display: grid;
  grid-auto-flow: column;
  min-width: 0;
  max-width: 100%;
  width: 100%;
  height: 90%;
}

.chart-area {
  min-width: 0;
  overflow: hidden;
}

.font-face-open {
 font-family: "Open Sans";
}

.font-face-open-bold {
 font-family: "Open Sans Bold";
}
